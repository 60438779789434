


class InvitePage {

	constructor(props) {



	}

	ready = () => {

		var pathName = window.location.pathname;
		console.log('Invite PAGE READY', pathName);

		this.identify();


		if (pathName.toLowerCase().startsWith("/invite/confirm")) { this.confirm(); }
		if (pathName.toLowerCase().startsWith("/invite/unsubscribe")) { this.unsubscribe(); }
	}

	identify = () => {

		

	}

	confirmEmail = (email) => {

		
	}

	unsubscribeEmail = (email) => {

				
	}

	confirm = () => {

		const email = this.getEmail();

		if (email != null) {

			this.confirmEmail(email);
		}
	}

	unsubscribe = () => {

		const email = this.getEmail();

		if (email != null) {
			this.unsubscribeEmail(email);
		}
	}

	getEmail = () => {
		return ((window.pageData) && (typeof window.pageData.email === 'string')) ? window.pageData.email : null;
	}


}

export default InvitePage;